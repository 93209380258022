import { APP_CONSTANS } from "shared/constants";

export const initialisePush = (token) => {
    if (!token) return;

    const subscribe = (token) => {
        // Are Notifications supported?
        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            return;
        }
        if (Notification.permission === 'denied') {
            return;
        }
        if (!('PushManager' in window)) {
            return;
        }

        // We need the service worker registration to check for a subscription
        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            // Do we already have a push message subscription?
            serviceWorkerRegistration.pushManager.getSubscription()
                .then((subscription) => {
                    if (!subscription) {
                        // We aren't subscribed to push
                        return;
                    }

                    // Keep server in sync with the latest subscriptionId
                    const auth_token = "bearer " + token;
                    const path = APP_CONSTANS.BASE_NOTIFY_URL + "/notify/push_subscription";
                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", path);
                    xhr.setRequestHeader('Authorization', auth_token);
                    xhr.send(JSON.stringify(subscription));
                })
                .catch((err) => {
                    console.warn('Error during getSubscription()', err);
                });
        });
    }
    
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
            .then(() => {
                return subscribe(token)
            });
    } else {
        console.warn('Service workers aren\'t supported in this browser.');
    }
}