import { ModalWindow } from 'components/ModalWindow';
import styles from './styles.module.scss';
import { useCallback, useMemo } from 'react';
import { Button, Image } from 'antd';
import attentionIcon from 'shared/images/attention.svg';
import clsx from 'clsx';

export const SubmitCheckInModal = ({
  show,
  close,
  handleCheckButtonClick,
  contentText,
  disabled = false,
}) => {
  const handleModalClose = useCallback(() => {
    close(false);
  }, [close]);

  const handleModalSubmit = useCallback(() => {
    handleCheckButtonClick();
    close(false);
  }, [close, handleCheckButtonClick]);

  const content = (() => (
    <div className={styles.content}>
      <Image
        className={styles.image}
        src={attentionIcon}
        preview={false}
        alt="attention"
      />
      {contentText && <p className={styles.text}>{contentText}</p>}
    </div>
  ))();

  const footer = useMemo(
    () => (
      <div className={styles.footer}>
        {disabled 
          ? (
            <Button
              className={styles.footerButton}
              onClick={handleModalClose}
              size="large"
            >
              Close
            </Button>
          )
          : (
            <>
              <Button
                className={styles.footerButton}
                onClick={handleModalClose}
                size="large"
              >
                Cancel
              </Button>
              <Button
                className={clsx(styles.footerButton, styles.checkInButton)}
                onClick={handleModalSubmit}
                type="primary"
                size="large"
                data-e2e="checkInModalBtn"
              >
                Check in
              </Button>
            </>
          )
        }
      </div>
    ),
    [handleModalClose, handleModalSubmit, disabled],
  );

  return (
    <ModalWindow
      closable={false}
      notToRiseTop={true}
      modalVisible={show}
      modalHidden={close}
      footer={footer}
      content={content}
      smallBlock
    />
  );
};
