import { useEffect, useState } from 'react';
import { Button, Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { APP_CONSTANS } from '../../../shared/constants';
import { postUserIosNotify } from '../../../store/user/actions';
import styles from './styles.module.scss';

export const AlertNewApp = () => {
  const dispatch = useDispatch();
  const userPreference = useSelector((state) => state.user.userPrefs);
  const [showNewAppNotify, setShowNewAppNotify] = useState(false);
  const [isIPhone, setIsIPhone] = useState(false);
  const [prefId, setPrefId] = useState('');

  useEffect(() => {
    if (navigator?.userAgent?.includes('iPhone')) {
      setIsIPhone(true);
    }
  }, []);
  
  useEffect(() => {
    if (!Array.isArray(userPreference)) return;
    const isViewIosCode = APP_CONSTANS.PHOTOGRAPHER_PROPS.IS_VIEW_IOS_APP_NOTIFY_CODE;
    const preference = userPreference.find((e) => e?.preference?.pref_code === isViewIosCode);
    const isSowedNotify = preference?.value;
    if (isSowedNotify === false || isSowedNotify === 'false') {
      setShowNewAppNotify(true);
      setPrefId(preference?.preference_id);
    }
  }, [userPreference]);

  const handleOnClose = () => {
    dispatch(postUserIosNotify(prefId));
  };

  const handleOnClick = () => {
    handleOnClose();
    setShowNewAppNotify(false);
    window.open(APP_CONSTANS.IOS_APPSTORE_LINK);
  };

  return (
    showNewAppNotify && isIPhone && (
      <Alert
        message={(
          <span className={styles.title}>
            <img src='./ff-favicon.svg' width={20} height={20} alt='Small logo'/>
            <span>Open in the FotoFetch app</span>
          </span>
        )}
        type="warning"
        showIcon={false}
        banner
        onClose={handleOnClose}
        action={
          <Button 
            className={styles.openBtn}
            size="small" 
            type="primary"
            onClick={handleOnClick}
          >
            OPEN
          </Button>
        }
        closable
      />
    )
  );
};
