import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  message: {
    id: 0,
    type: '',
    status: '',
    content: '',
  },
};

export const messaging = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: payload };

    case ACTION_TYPES.CLEAR_MESSAGE:
      return {
        ...state,
        message: { id: 0, type: '', status: '', content: '' },
      };

    default:
      return state;
  }
};
