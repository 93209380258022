import { APP_CONSTANS } from "shared/constants";

const { FULFILMENT, JOBS, JOB, ORDER, ORDERS } = APP_CONSTANS;

export const APP_PATH = {
  ROOT: '/',
  JOB: `/:jobType(${FULFILMENT}|${JOBS}|${JOB}|${ORDER}|${ORDERS})/:id`,
  PROFILE: '/profile',
  PROFILE_ADDRESS: '/profile/address',
  PROFILE_ACCOUNT: '/profile/account',
  PROFILE_PAYMENT: '/profile/payment',
  PROFILE_PASSWORD: '/profile/password',
  PROFILE_NOTIFICATIONS: '/profile/notifications',
  ERROR: '/error',
  WILDCARD: '*',
  FAQ: '/faq',
  PRIVACY_POLICY: '/privacy',
  TERMS_OF_SERVICE: '/terms',
  SUPERUSER: '/admin/dashboard',
  CUSTOMER: '/orders',
  ORDER_EDITOR: '/orders/editor',
  PHOTO_EDITOR: '/jobs/editor',
};
