import { APP_CONSTANS } from '../constants';
import { signOutProcess } from './signout';

export const isSessionLife = () => {
  const delay =
    APP_CONSTANS.SESSION_LIFE_IN_MINUTES * APP_CONSTANS.ONE_MINUTE_IN_MS -
    APP_CONSTANS.ONE_MINUTE_IN_MS;
  let timerId = setTimeout(function tick() {
    const now = Date.now();
    const sessionExpTime = window.localStorage.getItem(
      APP_CONSTANS.LS_SESSION_EXP,
    );
    if (sessionExpTime <= now) {
      clearTimeout(timerId);
      signOutProcess();
      return;
    }
    if (sessionExpTime <= now + APP_CONSTANS.ONE_MINUTE_IN_MS) {
      //TODO: add alert
      clearTimeout(timerId);
      signOutProcess();
      return;
    }
    timerId = setTimeout(
      tick,
      sessionExpTime > now + APP_CONSTANS.ONE_MINUTE_IN_MS * 2
        ? sessionExpTime - APP_CONSTANS.ONE_MINUTE_IN_MS
        : APP_CONSTANS.ONE_MINUTE_IN_MS,
    );
  }, delay);
};
