import { EnableLocationModal } from './components/EnableLocationModal';
import { SubmitCheckInModal } from './components/SubmitCheckInModal';
import styles from './styles.module.scss';

export const CheckIn = ({ modals, handleCheckButtonClick }) => {
  const {
    showLocationEnableModal,
    showLongDistanceModal,
    showCheckInWithoutLocationModal,
    showGetPositionError,
    setShowLocationEnableModal,
    setShowLongDistanceModal,
    setShowCheckInWithoutLocationModal,
    setShowGetPositionError,
  } = modals || {};

  return (
    <>
      {showLocationEnableModal && (
        <EnableLocationModal
          show={showLocationEnableModal}
          close={setShowLocationEnableModal}
        />
      )}

      {showLongDistanceModal && (
        <SubmitCheckInModal
          show={showLongDistanceModal}
          close={setShowLongDistanceModal}
          handleCheckButtonClick={handleCheckButtonClick}
          contentText={
            <div data-e2e="checkInModal">
              <span>
                Your location is currently not in close proximity to the job. We
                recommend that you check in only when you are on site.
              </span>
              <span className={styles.subtext}>
                Do you still want to check in?
              </span>
            </div>
          }
        />
      )}

      {showCheckInWithoutLocationModal && (
        <SubmitCheckInModal
          show={showCheckInWithoutLocationModal}
          close={setShowCheckInWithoutLocationModal}
          contentText="The system doesn’t have access to your location."
          disabled={true}
        />
      )}

      {showGetPositionError && (
         <SubmitCheckInModal
          show={showGetPositionError}
          close={setShowGetPositionError}
          contentText="Sorry, we can't get high accurancy geolocation."
          disabled={true}
        />
      )}
    </>
  );
};
