export const ACTION_TYPES = Object.freeze({
  SET_TOKEN: '@@AUTH/SET_TOKEN',
  SET_MANUAL_LOGOUT: '@@AUTH/SET_MANUAL_LOGOUT',
  GET_TOKEN: '@@AUTH/GET_TOKEN',
  GET_TOKEN_PENDING: '@@AUTH/GET_TOKEN_PENDING',
  GET_TOKEN_FULFILLED: '@@AUTH/GET_TOKEN_FULFILLED',
  GET_TOKEN_REJECTED: '@@AUTH/GET_TOKEN_REJECTED',
  GET_SYSTEM_PREFERENCE: '@@AUTH/GET_SYSTEM_PREFERENCE',
  GET_SYSTEM_PREFERENCE_PENDING: '@@AUTH/GET_SYSTEM_PREFERENCE_PENDING',
  GET_SYSTEM_PREFERENCE_FULFILLED: '@@AUTH/GET_SYSTEM_PREFERENCE_FULFILLED',
  GET_SYSTEM_PREFERENCE_REJECTED: '@@AUTH/GET_SYSTEM_PREFERENCE_REJECTED',
  GET_SYSTEM_PREFERENCES_ALL: '@@AUTH/GET_SYSTEM_PREFERENCES_ALL',
  GET_SYSTEM_PREFERENCES_ALL_PENDING: '@@AUTH/GET_SYSTEM_PREFERENCES_ALL_PENDING',
  GET_SYSTEM_PREFERENCES_ALL_FULFILLED: '@@AUTH/GET_SYSTEM_PREFERENCES_ALL_FULFILLED',
  GET_SYSTEM_PREFERENCES_ALL_REJECTED: '@@AUTH/GET_SYSTEM_PREFERENCES_ALL_REJECTED',
  SIGN_OUT: '@@AUTH/SIGN_OUT',
  SIGN_OUT_PENDING: '@@AUTH/SIGN_OUT_PENDING',
  SIGN_OUT_FULFILLED: '@@AUTH/SIGN_OUT_FULFILLED',
  SIGN_OUT_REJECTED: '@@AUTH/SIGN_OUT_REJECTED',
});
