import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  orders: [],
  fulfilments: [],
  currentOrder: null,
  currentJob: null,
  currentJobDescription: { id: '', description: '' },
  currentJobDescriptionID: null,
  currentJobId: '',
  currentJobRequests: [],
  currentJobCheckPoints: { data: [] },
  currentJobActiveCheckPoint: null,
  isLoadingCurrentJobRequests: false,
  isLoadingOrders: null,
  isLoadingFulfilments: null,
  isLoadingCurrentOrder: false,
  isLoadingCurrentJob: false,
  isLoadingCurrentJobDescription: false,
  isLoadingCurrentJobCheckPoints: true,
  filesInFulfilment: [],
  isLoadingFilesInFulfilment: false,
  deletedFileId: null,
  extendTime: null,
  currentComments: null,
  isLoadingCommentsInFulfilment: false,
  currentComplications: null,
  isLoadingCurrentComplications: false,
  timeExtension: null,
  submittedJob: null,
  readedComments: null,
  allComplications: null,
  isLoadingAllComplications: false,
  allComplicationsChildren: null,
  isLoadingAllComplicationsChildren: false,
  complicationChild: null,
  currencies: null,
  isLoadingCurrencies: null,
  isLoadingSetAcceptedJob: false,
  jobStates: null,
  isLoadingJobStates: false,
};

export const jobs = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_JOBS_ORDERS_PENDING:
      return { ...state, isLoadingOrders: true };
    case ACTION_TYPES.GET_JOBS_ORDERS_FULFILLED:
      return { ...state, orders: payload, isLoadingOrders: false };
    case ACTION_TYPES.GET_JOBS_ORDERS_REJECTED:
      return { ...state, isLoadingOrders: false };

    case ACTION_TYPES.GET_JOBS_FULFILMENTS_PENDING:
      return { ...state, isLoadingFulfilments: true };
    case ACTION_TYPES.GET_JOBS_FULFILMENTS_FULFILLED:
      return {
        ...state,
        fulfilments: payload,
        isLoadingFulfilments: false,
      };
    case ACTION_TYPES.GET_JOBS_FULFILMENTS_REJECTED:
      return {
        ...state,
        isLoadingFulfilments: false,
      };

    case ACTION_TYPES.GET_JOBS_CURRENT_ORDER_PENDING:
      return { ...state, isLoadingCurrentOrder: true };
    case ACTION_TYPES.GET_JOBS_CURRENT_ORDER_FULFILLED:
      return {
        ...state,
        currentOrder: payload,
        isLoadingCurrentOrder: false,
      };
    case ACTION_TYPES.GET_JOBS_CURRENT_ORDER_REJECTED:
      return {
        ...state,
        isLoadingCurrentOrder: false,
      };

    case ACTION_TYPES.GET_JOBS_CURRENT_JOB_PENDING:
      return { ...state, isLoadingCurrentJob: true };
    case ACTION_TYPES.GET_JOBS_CURRENT_JOB_FULFILLED:
      return {
        ...state,
        currentJob: payload,
        isLoadingCurrentJob: false,
      };
    case ACTION_TYPES.GET_JOBS_CURRENT_JOB_REJECTED:
      return {
        ...state,
        isLoadingCurrentJob: false,
      };

    case ACTION_TYPES.GET_JOBS_COMPLICATIONS_PENDING:
      return { ...state, isLoadingCurrentComplications: true };
    case ACTION_TYPES.GET_JOBS_COMPLICATIONS_FULFILLED:
      return {
        ...state,
        currentComplications: payload,
        isLoadingCurrentComplications: false,
      };
    case ACTION_TYPES.GET_JOBS_COMPLICATIONS_REJECTED:
      return {
        ...state,
        isLoadingCurrentComplications: false,
      };

    case ACTION_TYPES.GET_ALL_COMPLICATIONS_PENDING:
      return { ...state, isLoadingAllComplications: true };
    case ACTION_TYPES.GET_ALL_COMPLICATIONS_FULFILLED:
      return {
        ...state,
        allComplications: payload,
        isLoadingAllComplications: false,
      };
    case ACTION_TYPES.GET_ALL_COMPLICATIONS_REJECTED:
      return {
        ...state,
        isLoadingAllComplications: false,
      };

    case ACTION_TYPES.GET_ALL_COMPLICATIONS_CHILDREN_PENDING:
      return { ...state, isLoadingAllComplicationsChildren: true };
    case ACTION_TYPES.GET_ALL_COMPLICATIONS_CHILDREN_FULFILLED:
      return {
        ...state,
        allComplicationsChildren: payload,
        isLoadingAllComplicationsChildren: false,
      };
    case ACTION_TYPES.GET_ALL_COMPLICATIONS_CHILDREN_REJECTED:
      return {
        ...state,
        isLoadingAllComplicationsChildren: false,
      };

    case ACTION_TYPES.GET_COMPLICATION_CHILD_PENDING:
      return { ...state, isLoadingAllComplicationsChildren: true };
    case ACTION_TYPES.GET_COMPLICATION_CHILD_FULFILLED:
      return {
        ...state,
        complicationChild: payload,
        isLoadingAllComplicationsChildren: false,
      };
    case ACTION_TYPES.GET_COMPLICATION_CHILD_REJECTED:
      return {
        ...state,
        isLoadingAllComplicationsChildren: false,
      };

    case ACTION_TYPES.GET_JOBS_CURRENT_JOB_CHECK_POINTS_PENDING:
      return { ...state, isLoadingCurrentJobCheckPoints: true };
    case ACTION_TYPES.GET_JOBS_CURRENT_JOB_CHECK_POINTS_FULFILLED:
      return {
        ...state,
        currentJobCheckPoints: payload,
        isLoadingCurrentJobCheckPoints: false,
      };
    case ACTION_TYPES.GET_JOBS_CURRENT_JOB_CHECK_POINTS_REJECTED:
      return {
        ...state,
        isLoadingCurrentJobCheckPoints: false,
      };

    case ACTION_TYPES.GET_JOBS_CURRENT_FULFILMENT_PENDING:
      return { ...state, isLoadingCurrentJob: true };
    case ACTION_TYPES.GET_JOBS_CURRENT_FULFILMENT_FULFILLED:
      return {
        ...state,
        currentJob: payload,
        isLoadingCurrentJob: false,
      };
    case ACTION_TYPES.GET_JOBS_CURRENT_FULFILMENT_REJECTED:
      return {
        ...state,
        isLoadingCurrentJob: false,
      };

    case ACTION_TYPES.GET_CURRENCIES_PENDING:
      return { ...state, isLoadingCurrencies: true };
    case ACTION_TYPES.GET_CURRENCIES_FULFILLED:
      return { ...state, currencies: payload, isLoadingCurrencies: false };
    case ACTION_TYPES.GET_CURRENCIES_REJECTED:
      return { ...state, isLoadingCurrencies: false };

    case ACTION_TYPES.SET_JOBS_ACCEPT_ORDER_PENDING:
      return { ...state, isLoadingSetAcceptedJob: true };
    case ACTION_TYPES.SET_JOBS_ACCEPT_ORDER_FULFILLED:
      return { ...state, isLoadingSetAcceptedJob: false };
    case ACTION_TYPES.SET_JOBS_ACCEPT_ORDER_REJECTED:
      return { ...state, isLoadingSetAcceptedJob: false };

    case ACTION_TYPES.GET_JOBS_CURRENT_DESCRIPTION_PENDING:
      return { ...state, isLoadingCurrentJobDescription: true };
    case ACTION_TYPES.GET_JOBS_CURRENT_DESCRIPTION_FULFILLED:
      return {
        ...state,
        currentJobDescription: payload,
        isLoadingCurrentJobDescription: false,
      };
    case ACTION_TYPES.GET_JOBS_CURRENT_DESCRIPTION_REJECTED:
      return {
        ...state,
        currentJobDescription: { id: '', description: '' },
        isLoadingCurrentJobDescription: false,
      };

    case ACTION_TYPES.GET_JOBS_FILES_IN_FULFILMENT_PENDING:
      return { ...state, isLoadingFilesInFulfilment: true };
    case ACTION_TYPES.GET_JOBS_FILES_IN_FULFILMENT_FULFILLED:
      return {
        ...state,
        filesInFulfilment: payload,
        isLoadingFilesInFulfilment: false,
      };
    case ACTION_TYPES.GET_JOBS_FILES_IN_FULFILMENT_REJECTED:
      return {
        ...state,
        isLoadingFilesInFulfilment: false,
      };

    case ACTION_TYPES.GET_JOBS_COMMENTS_IN_FULFILMENT_PENDING:
      return { ...state, isLoadingCommentsInFulfilment: true };
    case ACTION_TYPES.GET_JOBS_COMMENTS_IN_FULFILMENT_FULFILLED:
      return {
        ...state,
        currentComments: payload,
        isLoadingCommentsInFulfilment: false,
      };
    case ACTION_TYPES.GET_JOBS_COMMENTS_IN_FULFILMENT_REJECTED:
      return {
        ...state,
        isLoadingCommentsInFulfilment: false,
      };

    case ACTION_TYPES.GET_JOBS_REQUESTS_LIST_PENDING:
      return { ...state, isLoadingCurrentJobRequests: true };
    case ACTION_TYPES.GET_JOBS_REQUESTS_LIST_FULFILLED:
      return {
        ...state,
        currentJobRequests: payload,
        isLoadingCurrentJobRequests: false,
      };
    case ACTION_TYPES.GET_JOBS_REQUESTS_LIST_REJECTED:
      return {
        ...state,
        isLoadingCurrentJobRequests: false,
      };

    case ACTION_TYPES.GET_JOB_STATES_PENDING:
      return { ...state, isLoadingJobStates: true };
    case ACTION_TYPES.GET_JOB_STATES_FULFILLED:
      return {
        ...state,
        jobStates: payload,
        isLoadingJobStates: false,
      };
    case ACTION_TYPES.GET_JOB_STATES_REJECTED:
      return {
        ...state,
        isLoadingJobStates: false,
      };

    case ACTION_TYPES.SET_JOBS_CURRENT_JOB_ID:
      return { ...state, currentJobId: payload };
    case ACTION_TYPES.SET_JOBS_CURRENT_JOB_DESCRIPTION_ID:
      return { ...state, currentJobDescriptionID: payload };
    case ACTION_TYPES.SET_JOBS_CURRENT_JOB_SUBMIT:
      return { ...state, submittedJob: payload };

    case ACTION_TYPES.SET_JOBS_DECLINE_CURRENT_FULFILMENT:
      return { ...state, currentJob: payload };
    case ACTION_TYPES.SET_JOBS_REQUEST_TIME_FULFILMENT:
      return { ...state, timeExtension: payload };
    case ACTION_TYPES.SET_JOBS_READED_COMMENTS_IN_FULFILMENT:
      return { ...state, readedComments: payload };

    case ACTION_TYPES.SET_JOBS_CURRENT_JOB_ACTIVE_CHECK_POINT:
      return {
        ...state,
        currentJobActiveCheckPoint: payload,
      };

    case ACTION_TYPES.DELETE_JOBS_CURRENT_FILE:
      return { ...state, deletedFileId: payload };

    case ACTION_TYPES.DELETE_CURRENT_JOB_DATA:
      return {
        ...state,
        currentJob: null,
        currentJobDescription: { id: '', description: '' },
        currentJobDescriptionID: null,
        currentJobId: '',
        currentComments: null,
        currentComplications: null,
        currentJobRequests: [],
      };
    case ACTION_TYPES.DELETE_FILES_IN_FULFILMENT:
      return {
        ...state,
        filesInFulfilment: [],
        isLoadingFilesInFulfilment: false,
      };

    default:
      return state;
  }
};
