import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import httpGlobalFetch from './http/middleware';
import { authMiddlewares, auth } from './auth';
import { userMiddlewares, user } from './user';
import { jobsMiddlewares, jobs } from './jobs';
import { messaging } from './messaging';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore() {
  const rootReducer = combineReducers({
    auth,
    user,
    jobs,
    messaging,
  });
  return createStore(
    rootReducer,
    undefined,
    composeEnhancers(
      applyMiddleware(
        ...authMiddlewares,
        ...userMiddlewares,
        ...jobsMiddlewares,
        httpGlobalFetch,
        thunk,
        promise,
      ),
    ),
  );
}

export * from './auth';
export * from './user';
