import { useEffect } from 'react';

import { noop } from '../utils/common.utils';

export const useOnMount = (callback = noop) => {
  useEffect(() => {
    callback();
    // because of a need to trigger the hook only on mount phase
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
