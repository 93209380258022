import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppContainerWrapper from './features/AppContainer/AppContainerWrapper';
import 'antd/dist/antd.css';
import './App.scss';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Layout } from 'antd';
// import useWindowSize from './shared/hooks/use_window_size.hook';

const { Content } = Layout;

// export const WindowSizeContext = React.createContext();

function App() {
  // const window_size = useWindowSize();
  return (
    // <WindowSizeContext.Provider value={window_size}>
    <BrowserRouter>
      <Layout>
        <Header />
        <Content className="site-layout">
          <AppContainerWrapper />
        </Content>
        <Footer />
      </Layout>
    </BrowserRouter>
    // </WindowSizeContext.Provider>
  );
}

export default App;
