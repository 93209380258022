const theme = {
  colors: {
    neutral300: '#B3BFC6',
  },
  shadows: {
    menuList: '0px 1px 4px rgba(0, 0, 0, 0.12)',
    popover: '0px 16px 24px rgba(0, 0, 0, 0.04)',
    modal: ' 0px 2px 12px rgba(0, 0, 0, 0.16)',
    tile: '0px 4px 8px rgba(238, 240, 241, 0.48)',
  },
  shape: {
    borderRadius2px: '2px',
    borderRadius4px: '4px',
    borderRadius8px: '8px',
  },
  screenSize: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1536px',
  },
};

export default theme;
