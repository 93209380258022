import { ACTION_TYPES } from './types';

export const setMessage = (type, status, content) => {
  const newId = type && status && content ? Date.now() : '';
  const newType = type ? type : '';
  const newStatus = status ? status : '';
  const newContent = content ? content : '';
  return {
    type: ACTION_TYPES.SET_MESSAGE,
    payload: {
      id: newId,
      type: newType,
      status: newStatus,
      content: newContent,
    },
  };
};

export const clearMessage = () => {
  return {
    type: ACTION_TYPES.CLEAR_MESSAGE,
  };
};
