import haversine from 'haversine-distance';

export const calculateDistanceBetweenTwoPoints = ({
  lat1,
  lng1,
  lat2,
  lng2,
}) => {
  const point1 = { latitude: lat1, longitude: lng1 };
  const point2 = { latitude: lat2, longitude: lng2 };

  const haversineDistanceInMeters = haversine(point1, point2);

  return haversineDistanceInMeters;
};
