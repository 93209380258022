import { ModalWindow } from 'components/ModalWindow';
import styles from './styles.module.scss';
import { useCallback, useMemo } from 'react';
import { Button, Image } from 'antd';
import locationMarker from 'shared/images/locate.svg';
import clsx from 'clsx';

export const EnableLocationModal = ({ show, close }) => {
  const handleModalClose = useCallback(() => {
    close(false);
  }, [close]);

  const content = (() => (
    <div className={styles.content}>
      <Image
        className={styles.image}
        src={locationMarker}
        preview={false}
        alt="location marker"
      />
      <p className={styles.text}>
        FotoFetch needs access to your location while using the service. Please
        grant location based access to FotoFetch.com from your internet browser.
      </p>
    </div>
  ))();

  const footer = useMemo(
    () => (
      <div className={styles.footer}>
        <Button
          className={styles.footerButton}
          onClick={handleModalClose}
          type="primary"
          size="large"
        >
          Got it
        </Button>
        <p className={clsx(styles.text, styles.subtext)}>
          Applying new settings may take time or require you to reload the page
        </p>
      </div>
    ),
    [handleModalClose],
  );
  return (
    <ModalWindow
      closable={false}
      notToRiseTop={true}
      modalVisible={show}
      modalHidden={close}
      footer={footer}
      content={content}
      smallBlock
    />
  );
};
