export const ACTION_TYPES = Object.freeze({
  GET_USER: '@@USER/GET_USER',
  GET_USER_PENDING: '@@USER/GET_USER_PENDING',
  GET_USER_FULFILLED: '@@USER/GET_USER_FULFILLED',
  GET_USER_REJECTED: '@@USER/GET_USER_REJECTED',

  GET_USER_ONBOARD: '@@USER/GET_USER_ONBOARD',
  GET_USER_ONBOARD_PENDING: '@@USER/GET_USER_ONBOARD_PENDING',
  GET_USER_ONBOARD_FULFILLED: '@@USER/GET_USER_ONBOARD_FULFILLED',
  GET_USER_ONBOARD_REJECTED: '@@USER/GET_USER_ONBOARD_REJECTED',

  GET_USER_PREFS: '@@USER_PREFS/GET_USER_PREFS',
  GET_USER_PREFS_PENDING: '@@USER_PREFS/GET_USER_PREFS_PENDING',
  GET_USER_PREFS_FULFILLED: '@@USER_PREFS/GET_USER_PREFS_FULFILLED',
  GET_USER_PREFS_REJECTED: '@@USER_PREFS/GET_USER_PREFS_REJECTED',
  GET_USER_CLAIMS: '@@USER_CLAIMS/GET_USER_CLAIMS',
  GET_USER_CLAIMS_PENDING: '@@USER_CLAIMS/GET_USER_CLAIMS_PENDING',
  GET_USER_CLAIMS_FULFILLED: '@@USER_CLAIMS/GET_USER_CLAIMS_FULFILLED',
  GET_USER_CLAIMS_REJECTED: '@@USER_CLAIMS/GET_USER_CLAIMS_REJECTED',
  GET_USER_ACCOUNT: '@@USER_ACCOUNT/GET_USER_ACCOUNT',
  GET_USER_ACCOUNT_PENDING: '@@USER_ACCOUNT/GET_USER_ACCOUNT_PENDING',
  GET_USER_ACCOUNT_FULFILLED: '@@USER_ACCOUNT/GET_USER_ACCOUNT_FULFILLED',
  GET_USER_ACCOUNT_REJECTED: '@@USER_ACCOUNT/GET_USER_ACCOUNT_REJECTED',
  GET_USER_ACCOUNT_PREFS: '@@USER_ACCOUNT/GET_USER_ACCOUNT_PREFS',
  GET_USER_ACCOUNT_PREFS_PENDING:
    '@@USER_ACCOUNT/GET_USER_ACCOUNT_PREFS_PENDING',
  GET_USER_ACCOUNT_PREFS_FULFILLED:
    '@@USER_ACCOUNT/GET_USER_ACCOUNT_PREFS_FULFILLED',
  GET_USER_ACCOUNT_PREFS_REJECTED:
    '@@USER_ACCOUNT/GET_USER_ACCOUNT_PREFS_REJECTED',
  GET_USER_ADDRESSES: '@@USER_ADDRESSES/GET_USER_ADDRESSES',
  GET_USER_ADDRESSES_PENDING: '@@USER_ADDRESSES/GET_USER_ADDRESSES_PENDING',
  GET_USER_ADDRESSES_FULFILLED: '@@USER_ADDRESSES/GET_USER_ADDRESSES_FULFILLED',
  GET_USER_ADDRESSES_REJECTED: '@@USER_ADDRESSES/GET_USER_ADDRESSES_REJECTED',
  GET_USER_COUNTRIES: '@@USER_ADDRESSES/GET_USER_COUNTRIES',
  GET_USER_COUNTRIES_PENDING: '@@USER_ADDRESSES/GET_USER_COUNTRIES_PENDING',
  GET_USER_COUNTRIES_FULFILLED: '@@USER_ADDRESSES/GET_USER_COUNTRIES_FULFILLED',
  GET_USER_COUNTRIES_REJECTED: '@@USER_ADDRESSES/GET_USER_COUNTRIES_REJECTED',
  GET_USER_REGIONS: '@@USER_ADDRESSES/GET_USER_REGIONS',
  GET_USER_REGIONS_PENDING: '@@USER_ADDRESSES/GET_USER_REGIONS_PENDING',
  GET_USER_REGIONS_FULFILLED: '@@USER_ADDRESSES/GET_USER_REGIONS_FULFILLED',
  GET_USER_REGIONS_REJECTED: '@@USER_ADDRESSES/GET_USER_REGIONS_REJECTED',
  GET_USER_REQUESTS: '@@USER_REQUESTS/GET_USER_REQUESTS',
  GET_USER_REQUESTS_PENDING: '@@USER_REQUESTS/GET_USER_REQUESTS_PENDING',
  GET_USER_REQUESTS_FULFILLED: '@@USER_REQUESTS/GET_USER_REQUESTS_FULFILLED',
  GET_USER_REQUESTS_REJECTED: '@@USER_REQUESTS/GET_USER_REQUESTS_REJECTED',
  GET_USER_PURCHASE_METHODS: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS',
  GET_USER_PURCHASE_METHODS_PENDING: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS_PENDING',
  GET_USER_PURCHASE_METHODS_FULFILLED: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS_FULFILLED',
  GET_USER_PURCHASE_METHODS_REJECTED: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS_REJECTED',
  GET_USER_PURCHASE_METHODS_COUNT: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS_COUNT',
  GET_USER_PURCHASE_METHODS_COUNT_PENDING: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS_COUNT_PENDING',
  GET_USER_PURCHASE_METHODS_COUNT_FULFILLED: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS_COUNT_FULFILLED',
  GET_USER_PURCHASE_METHODS_COUNT_REJECTED: '@@USER_PURCHASE_METHODS/GET_USER_PURCHASE_METHODS_COUNT_REJECTED',
  GET_USER_PURCHASE_TARGETS: '@@USER_PURCHASE_TARGETS/GET_USER_PURCHASE_TARGETS',
  GET_USER_PURCHASE_TARGETS_PENDING: '@@USER_PURCHASE_TARGETS/GET_USER_PURCHASE_TARGETS_PENDING',
  GET_USER_PURCHASE_TARGETS_FULFILLED: '@@USER_PURCHASE_TARGETS/GET_USER_PURCHASE_TARGETS_FULFILLED',
  GET_USER_PURCHASE_TARGETS_REJECTED: '@@USER_PURCHASE_TARGETS/GET_USER_PURCHASE_TARGETS_REJECTED',
  GET_USER_LOCATION: '@@USER_LOCATION/GET_USER_LOCATION',
  SET_USER_LOCATION_STATUS: '@@USER_LOCATION_STATUS/SET_USER_LOCATION_STATUS',
  SET_USER_LEVEL_UPGRADE: '@@USER/SET_USER_LEVEL_UPGRADE',
  SET_USER_COMMENT: '@@USER/SET_USER_COMMENT',
  SET_USER_SHOW_ONBOARD: '@@USER/SET_USER_SHOW_ONBOARD',
  SET_USER_IS_SHOW_BOARD: '@@USER/SET_USER_IS_SHOW_BOARD',
  POST_USER_PREFS_NOTIFICATIONS: '@@USER_POST_USER_PREFS_NOTIFICATIONS',
  POST_USER_PREFS_NOTIFICATIONS_PENDING:
    '@@USER_POST_USER_PREFS_NOTIFICATIONS_PENDING',
  POST_USER_PREFS_NOTIFICATIONS_FULFILLED:
    '@@USER_POST_USER_PREFS_NOTIFICATIONS_FULFILLED',
  POST_USER_PREFS_NOTIFICATIONS_REJECTED:
    '@@USER_POST_USER_PREFS_NOTIFICATIONS_REJECTED',
  POST_ACCOUNT_PREFS: '@@USER_POST_ACCOUNT_PREFS',
  POST_ACCOUNT_PREFS_PENDING: '@@USER_POST_ACCOUNT_PREFS_PENDING',
  POST_ACCOUNT_PREFS_FULFILLED: '@@USER_POST_ACCOUNT_PREFS_FULFILLED',
  POST_ACCOUNT_PREFS_REJECTED: '@@USER_POST_ACCOUNT_PREFS_REJECTED',
  POST_USER_PREF_IOS_APP_NOTIFY: '@@USER_POST_USER_PREF_IOS_APP_NOTIFY',
  POST_USER_PREF_IOS_APP_NOTIFY_PENDING: '@@USER_POST_USER_PREF_IOS_APP_NOTIFY_PENDING',
  POST_USER_PREF_IOS_APP_NOTIFY_FULFILLED: '@@USER_POST_USER_PREF_IOS_APP_NOTIFY_FULFILLED',
  POST_USER_PREF_IOS_APP_NOTIFY_REJECTED: '@@USER_POST_USER_PREF_IOS_APP_NOTIFY_REJECTED',
  POST_USER_SHOW_ONBOARD: '@@USER/POST_USER_SHOW_ONBOARD',
  POST_USER_SHOW_ONBOARD_PENDING: '@@USER/POST_USER_SHOW_ONBOARD_PENDING',
  POST_USER_SHOW_ONBOARD_FULFILLED: '@@USER/POST_USER_SHOW_ONBOARD_FULFILLED',
  POST_USER_SHOW_ONBOARD_REJECTED: '@@USER/POST_USER_SHOW_ONBOARD_REJECTED',
  POST_USER_PURCHASE_METHOD: '@@USER/POST_USER_PURCHASE_METHOD',
  POST_USER_PURCHASE_METHOD_PENDING: '@@USER/POST_USER_PURCHASE_METHOD_PENDING',
  POST_USER_PURCHASE_METHOD_FULFILLED: '@@USER/POST_USER_PURCHASE_METHOD_FULFILLED',
  POST_USER_PURCHASE_METHOD_REJECTED: '@@USER/POST_USER_PURCHASE_METHOD_REJECTED',
  PATCH_USER: '@@USER_PATCH_USER',
  PATCH_USER_PENDING: '@@USER_PATCH_USER_PENDING',
  PATCH_USER_FULFILLED: '@@USER_PATCH_USER_FULFILLED',
  PATCH_USER_REJECTED: '@@USER_PATCH_USER_REJECTED',
  PATCH_USER_PREFS_CHANGE_PASSWORD: '@@USER_PATCH_USER_PREFS_CHANGE_PASSWORD',
  PATCH_USER_PREFS_CHANGE_PASSWORD_PENDING:
    '@@USER_PATCH_USER_PREFS_CHANGE_PASSWORD_PENDING',
  PATCH_USER_PREFS_CHANGE_PASSWORD_FULFILLED:
    '@@USER_PATCH_USER_PREFS_CHANGE_PASSWORD_FULFILLED',
  PATCH_USER_PREFS_CHANGE_PASSWORD_REJECTED:
    '@@USER_PATCH_USER_PREFS_CHANGE_PASSWORD_REJECTED',
  PATCH_USER_ACCOUNT: '@@USER_PATCH_USER_ACCOUNT',
  PATCH_USER_ACCOUNT_PENDING: '@@USER_PATCH_USER_ACCOUNT_PENDING',
  PATCH_USER_ACCOUNT_FULFILLED: '@@USER_PATCH_USER_ACCOUNT_FULFILLED',
  PATCH_USER_ACCOUNT_REJECTED: '@@USER_PATCH_USER_ACCOUNT_REJECTED',
  PATCH_USER_ADDRESS: '@@USER_PATCH_USER_ADDRESS',
  PATCH_USER_ADDRESS_PENDING: '@@USER_PATCH_USER_ADDRESS_PENDING',
  PATCH_USER_ADDRESS_FULFILLED: '@@USER_PATCH_USER_ADDRESS_FULFILLED',
  PATCH_USER_ADDRESS_REJECTED: '@@USER_PATCH_USER_ADDRESS_REJECTED',
  DELETE_USER_ACCOUNT: '@@USER_ACCOUNT/DELETE_USER_ACCOUNT',
  DELETE_USER_PURCHASE_METHOD: '@@USER/DELETE_USER_PURCHASE_METHOD',
  ALLOW_USER_GEOLOCATION: '@@USER_GEOLOCATION/ALLOW_USER_GEOLOCATION',
  PUT_USER_ACCOUNT: '@@USER/PUT_USER_ACCOUNT',
  PUT_USER_ACCOUNT_PENDING: '@@USER/PUT_USER_ACCOUNT_PENDING',
  PUT_USER_ACCOUNT_FULFILLED: '@@USER/PUT_USER_ACCOUNT_FULFILLED',
  PUT_USER_ACCOUNT_REJECTED: '@@USER/PUT_USER_ACCOUNT_REJECTED',
});
