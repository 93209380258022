import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  user: {},
  userPrefs: {},
  userClaims: {},
  userAccount: {},
  userAccountPrefs: {},
  userAddresses: [],
  userRequests: [],
  countries: [],
  regions: [],
  locationByCurrentPosition: null,
  levelUpgrade: null,
  currentPosition: {
    latitude: 0,
    longitude: 0,
  },
  isGeolocationAllowed: false,
  isLoadingUser: false,
  isLoadingUserPrefs: false,
  isLoadingUserRequests: false,
  isPostingUserPrefs: false,
  isLoadingCountries: false,
  isLoadingRegions: false,
  isLoadingOnboard: false,
  isLoadingShowOnboard: false,
  isLoadingSeenOnboard: false,
  comment: null,
  onBoarding: null,
  showOnBoarding: null,
  isShowBoard: false,
  alreadySeenOnboard: null,
  purchaseMethods: null,
  purchaseTargets: null,
  isLoadingPurchaseMethods: null,
  isLoadingPurchaseTargets: false,
  isPostingPurchaseMethod: false,
  isUpdatingAccount: null,
  purchaseMethodCount: null,
  isLoadingPurchaseCount: null,
};

export const user = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_USER_PENDING:
      return { ...state, isLoadingUser: true };
    case ACTION_TYPES.GET_USER_FULFILLED:
      return { ...state, user: payload, isLoadingUser: false };
    case ACTION_TYPES.GET_USER_REJECTED:
      return { ...state, isLoadingUser: false };

    case ACTION_TYPES.GET_USER_ONBOARD_PENDING:
      return { ...state, isLoadingOnboard: true };
    case ACTION_TYPES.GET_USER_ONBOARD_FULFILLED:
      return {
        ...state,
        onBoarding: payload,
        isLoadingOnboard: false,
      };
    case ACTION_TYPES.GET_USER_ONBOARD_REJECTED:
      return {
        ...state,
        onBoarding: payload,
        isLoadingOnboard: false,
      };

    case ACTION_TYPES.GET_USER_PREFS_PENDING:
      return { ...state, isLoadingUserPrefs: true };
    case ACTION_TYPES.GET_USER_PREFS_FULFILLED:
      return {
        ...state,
        userPrefs: payload,
        isLoadingUserPrefs: false,
      };
    case ACTION_TYPES.GET_USER_PREFS_REJECTED:
      return {
        ...state,
        isLoadingUserPrefs: false,
      };

    case ACTION_TYPES.GET_USER_CLAIMS_PENDING:
      return { ...state, isLoadingUserPrefs: true };
    case ACTION_TYPES.GET_USER_CLAIMS_FULFILLED:
      return {
        ...state,
        userClaims: payload,
        isLoadingUserPrefs: false,
      };
    case ACTION_TYPES.GET_USER_CLAIMS_REJECTED:
      return {
        ...state,
        isLoadingUserPrefs: false,
      };

    case ACTION_TYPES.GET_USER_ACCOUNT_PENDING:
      return { ...state, isLoadingUserPrefs: true };
    case ACTION_TYPES.GET_USER_ACCOUNT_FULFILLED:
      return {
        ...state,
        userAccount: payload,
        isLoadingUserPrefs: false,
      };
    case ACTION_TYPES.GET_USER_ACCOUNT_REJECTED:
      return {
        ...state,
        isLoadingUserPrefs: false,
      };

    case ACTION_TYPES.GET_USER_ACCOUNT_PREFS_PENDING:
      return { ...state, isLoadingUserPrefs: true };
    case ACTION_TYPES.GET_USER_ACCOUNT_PREFS_FULFILLED:
      return {
        ...state,
        userAccountPrefs: payload,
        isLoadingUserPrefs: false,
      };
    case ACTION_TYPES.GET_USER_ACCOUNT_PREFS_REJECTED:
      return {
        ...state,
        isLoadingUserPrefs: false,
      };

    case ACTION_TYPES.GET_USER_COUNTRIES:
      return { ...state, isLoadingCountries: true };
    case ACTION_TYPES.GET_USER_COUNTRIES_FULFILLED:
      return {
        ...state,
        countries: payload,
        isLoadingCountries: false,
      };
    case ACTION_TYPES.GET_USER_COUNTRIES_REJECTED:
      return {
        ...state,
        isLoadingCountries: false,
      };

    case ACTION_TYPES.GET_USER_REGIONS:
      return { ...state, isLoadingRegions: true };
    case ACTION_TYPES.GET_USER_REGIONS_FULFILLED:
      return { ...state, regions: payload, isLoadingRegions: false };
    case ACTION_TYPES.GET_USER_REGIONS_REJECTED:
      return { ...state, isLoadingRegions: false };

    case ACTION_TYPES.GET_USER_ADDRESSES_PENDING:
      return { ...state, isLoadingUserPrefs: true };
    case ACTION_TYPES.GET_USER_ADDRESSES_FULFILLED:
      return {
        ...state,
        userAddresses: payload,
        isLoadingUserPrefs: false,
      };
    case ACTION_TYPES.GET_USER_ADDRESSES_REJECTED:
      return {
        ...state,
        isLoadingUserPrefs: false,
      };

    case ACTION_TYPES.GET_USER_REQUESTS_PENDING:
      return { ...state, isLoadingUserRequests: true };
    case ACTION_TYPES.GET_USER_REQUESTS_FULFILLED:
      return {
        ...state,
        userRequests: payload,
        isLoadingUserRequests: false,
      };
    case ACTION_TYPES.GET_USER_REQUESTS_REJECTED:
      return {
        ...state,
        isLoadingUserRequests: false,
      };

    case ACTION_TYPES.GET_USER_LOCATION:
      return { ...state, currentPosition: payload };

    case ACTION_TYPES.GET_USER_PURCHASE_METHODS_PENDING:
      return { ...state, isLoadingPurchaseMethods: true };
    case ACTION_TYPES.GET_USER_PURCHASE_METHODS_FULFILLED:
      return {
        ...state,
        purchaseMethods: payload,
        isLoadingPurchaseMethods: false,
      };
    case ACTION_TYPES.GET_USER_PURCHASE_METHODS_REJECTED:
      return {
        ...state,
        isLoadingPurchaseMethods: false,
      };

    case ACTION_TYPES.GET_USER_PURCHASE_METHODS_COUNT_PENDING:
      return { ...state, isLoadingPurchaseCount: true };
    case ACTION_TYPES.GET_USER_PURCHASE_METHODS_COUNT_FULFILLED:
      return {
        ...state,
        purchaseMethodCount: payload,
        isLoadingPurchaseCount: false,
      };
    case ACTION_TYPES.GET_USER_PURCHASE_METHODS_COUNT_REJECTED:
      return {
        ...state,
        isLoadingPurchaseCount: false,
      };
  

    case ACTION_TYPES.GET_USER_PURCHASE_TARGETS_PENDING:
      return { ...state, isLoadingPurchaseTargets: true };
    case ACTION_TYPES.GET_USER_PURCHASE_TARGETS_FULFILLED:
      return {
        ...state,
        purchaseTargets: payload,
        isLoadingPurchaseTargets: false,
      };
    case ACTION_TYPES.GET_USER_PURCHASE_TARGETS_REJECTED:
      return {
        ...state,
        isLoadingPurchaseTargets: false,
      };

        
    case ACTION_TYPES.SET_USER_LOCATION_STATUS:
      return { ...state, locationByCurrentPosition: payload };
    case ACTION_TYPES.SET_USER_LEVEL_UPGRADE:
      return { ...state, levelUpgrade: payload };
    case ACTION_TYPES.SET_USER_COMMENT:
      return { ...state, comment: payload };
    case ACTION_TYPES.SET_USER_SHOW_ONBOARD:
      return { ...state, showOnBoarding: payload };
    case ACTION_TYPES.SET_USER_IS_SHOW_BOARD:
      return { ...state, isShowBoard: payload };

    case ACTION_TYPES.POST_USER_PREFS_NOTIFICATIONS_PENDING:
      return { ...state, isPostingUserPrefs: true };
    case ACTION_TYPES.POST_USER_PREFS_NOTIFICATIONS_FULFILLED:
      return { ...state, isPostingUserPrefs: false };
    case ACTION_TYPES.POST_USER_PREFS_NOTIFICATIONS_REJECTED:
      return { ...state, isPostingUserPrefs: false };

    case ACTION_TYPES.POST_USER_SHOW_ONBOARD_PENDING:
      return { ...state, isLoadingSeenOnboard: true };
    case ACTION_TYPES.POST_USER_SHOW_ONBOARD_FULFILLED:
      return {
        ...state,
        alreadySeenOnboard: payload,
        isLoadingSeenOnboard: false,
      };
    case ACTION_TYPES.POST_USER_SHOW_ONBOARD_REJECTED:
      return {
        ...state,
        isLoadingSeenOnboard: false,
      };

    case ACTION_TYPES.POST_USER_PURCHASE_METHOD_PENDING:
      return { ...state, isPostingPurchaseMethod: true };
    case ACTION_TYPES.POST_USER_PURCHASE_METHOD_FULFILLED:
      return { ...state, isPostingPurchaseMethod: false };
    case ACTION_TYPES.POST_USER_PURCHASE_METHOD_REJECTED:
      return { ...state, isPostingPurchaseMethod: false };

    case ACTION_TYPES.PUT_USER_ACCOUNT_PENDING:
      return { ...state, isUpdatingAccount: true };
    case ACTION_TYPES.PUT_USER_ACCOUNT_FULFILLED:
      return { ...state, isUpdatingAccount: false };
    case ACTION_TYPES.PUT_USER_ACCOUNT_REJECTED:
      return { ...state, isUpdatingAccount: false };

    case ACTION_TYPES.DELETE_USER_ACCOUNT:
      return { ...state, deletedUserAccount: payload };

    case ACTION_TYPES.ALLOW_USER_GEOLOCATION:
      return { ...state, isGeolocationAllowed: payload };

    default:
      return state;
  }
};
