import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import {
  convertUnixToLocalDateLong,
  convertUnixToLocalDate,
  convertUnixToLocalTime,
} from '../../../shared/utils/convertUNIXtoLocaleTime';
import { wrapLinks } from '../../../shared/utils/wrapLinks';

export const CommentItem = ({ index, comment, userId, currentComments }) => {
  const difBetweenDate = (prevDate, currentDate) => {
    if (prevDate && currentDate) {
      const prevConvertedDate = convertUnixToLocalDateLong(prevDate.utc_time);
      const nextConvertedDate = convertUnixToLocalDateLong(currentDate);
      if (prevConvertedDate !== nextConvertedDate) {
        return nextConvertedDate;
      } else {
        return false;
      }
    }
  };

  return (
    <li
      className={clsx(
        styles.commentItem,
        comment.user_id === userId ? styles.commentRight : styles.commentLeft,
      )}
    >
      <div className={styles.commentDate}>
        {index === 0 && comment.created_at
          ? convertUnixToLocalDate(comment.created_at)
          : difBetweenDate(currentComments[index - 1], comment.created_at)}
      </div>
      <div
        className={clsx(
          styles.blockComment,
          comment.user_id === userId
            ? styles.commentRightBg
            : styles.commentLeftBg,
        )}
      >
        <div className={styles.info}>
          <span className={styles.name}>{comment.user_name}</span>
          <span className={styles.position}>({comment.user_type})</span>
        </div>
        <p className={styles.comments}>{wrapLinks(comment.message, styles.linkInComment)}</p>
        <p className={styles.commentTime}>{convertUnixToLocalTime(comment.created_at)}</p>
      </div>
    </li>
  );
};
