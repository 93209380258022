import { ACTION_TYPES } from './types';

export const getOrders = () => {
  return {
    type: ACTION_TYPES.GET_JOBS_ORDERS,
    payload: [],
  };
};

export const getFulfilments = () => {
  return {
    type: ACTION_TYPES.GET_JOBS_FULFILMENTS,
    payload: [],
  };
};

export const getCurrentOrder = (history = null) => {
  return {
    type: ACTION_TYPES.GET_JOBS_CURRENT_JOB,
    payload: history,
  };
};

export const getCurrentOrderById = (id) => {
  return {
    type: ACTION_TYPES.GET_JOBS_CURRENT_ORDER,
    payload: id,
  };
};

export const getCurrentFulfilment = () => {
  return {
    type: ACTION_TYPES.GET_JOBS_CURRENT_FULFILMENT,
    payload: null,
  };
};

export const getCurrentJobDescription = () => {
  return {
    type: ACTION_TYPES.GET_JOBS_CURRENT_DESCRIPTION,
    payload: null,
  };
};

export const getAllComplications = () => {
  return {
    type: ACTION_TYPES.GET_ALL_COMPLICATIONS,
    payload: null,
  };
};

export const getAllComplicationsChildren = () => {
  return {
    type: ACTION_TYPES.GET_ALL_COMPLICATIONS_CHILDREN,
    payload: null,
  };
};

export const getComplicationChild = (childId) => {
  return {
    type: ACTION_TYPES.GET_COMPLICATION_CHILD,
    payload: childId,
  };
};

export const getCurrencies = () => {
  return {
    type: ACTION_TYPES.GET_CURRENCIES,
    payload: null,
  };
};

export const getCurrentJobCheckPoints = (fulfilmentId) => {
  return {
    type: ACTION_TYPES.GET_JOBS_CURRENT_JOB_CHECK_POINTS,
    payload: fulfilmentId,
  };
};

export const setCurrentJobActiveCheckPoint = (checkPoint) => {
  return {
    type: ACTION_TYPES.SET_JOBS_CURRENT_JOB_ACTIVE_CHECK_POINT,
    payload: checkPoint,
  };
};

export const setCurrentJobId = (id) => {
  return {
    type: ACTION_TYPES.SET_JOBS_CURRENT_JOB_ID,
    payload: id,
  };
};

export const setCurrentJobDescriptionId = (id) => {
  return {
    type: ACTION_TYPES.SET_JOBS_CURRENT_JOB_DESCRIPTION_ID,
    payload: id,
  };
};

export const setSubmittedJob = (data) => {
  return {
    type: ACTION_TYPES.SET_JOBS_CURRENT_JOB_SUBMIT,
    payload: data,
  };
};

export const getFilesInFulfilment = () => {
  return {
    type: ACTION_TYPES.GET_JOBS_FILES_IN_FULFILMENT,
    payload: null,
  };
};

export const getFulfilmentRequests = () => {
  return {
    type: ACTION_TYPES.GET_JOBS_REQUESTS_LIST,
    payload: null,
  };
};

export const getCommentsInFulfilment = () => {
  return {
    type: ACTION_TYPES.GET_JOBS_COMMENTS_IN_FULFILMENT,
    payload: null,
  };
};

export const getJobStates = () => {
  return {
    type: ACTION_TYPES.GET_JOB_STATES,
    payload: null,
  };
};

export const setAcceptOrder = (order) => {
  return {
    type: ACTION_TYPES.SET_JOBS_ACCEPT_ORDER,
    payload: order,
  };
};

export const setCurrentFulfilmentDecline = (data) => {
  return {
    type: ACTION_TYPES.SET_JOBS_DECLINE_CURRENT_FULFILMENT,
    payload: data,
  };
};

export const setRequestTimeFulfilment = (data) => {
  return {
    type: ACTION_TYPES.SET_JOBS_REQUEST_TIME_FULFILMENT,
    payload: data,
  };
};

export const setReadedComments = (data) => {
  return {
    type: ACTION_TYPES.SET_JOBS_READED_COMMENTS_IN_FULFILMENT,
    payload: data,
  };
};

export const deleteFileInFulfilment = (id) => {
  return {
    type: ACTION_TYPES.DELETE_JOBS_CURRENT_FILE,
    payload: id,
  };
};

export const deleteCurrentJobData = () => {
  return {
    type: ACTION_TYPES.DELETE_CURRENT_JOB_DATA,
  };
};

export const setEmptyFilesInStore = () => {
  return {
    type: ACTION_TYPES.DELETE_FILES_IN_FULFILMENT,
    payload: [],
  };
};

export const postCurrentJobCheckIn = ({ latitude, longitude }) => {
  return {
    type: ACTION_TYPES.POST_JOBS_CURRENT_JOB_CHECK_IN,
    payload: { latitude, longitude },
  };
};

export const postCurrentJobCheckOut = ({
  longitude,
  latitude,
  checkPointId,
}) => {
  return {
    type: ACTION_TYPES.POST_JOBS_CURRENT_JOB_CHECK_OUT,
    payload: { checkPointId, latitude, longitude },
  };
};
