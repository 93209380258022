import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOut, setManualLogout } from '../../../../store/auth/actions';
import { APP_PATH } from '../../../../features/Routes/helpers';
import { APP_CONSTANS } from '../../../../shared/constants';
import {
  LogoutOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  HomeOutlined,
  BulbOutlined,
} from '@ant-design/icons';
import styles from './styles.module.scss';

export const List = ({
  showListForSuperuser,
  setOpenedMenu,
  showOnboardingButton,
  setShowOnboarding,
}) => {
  const iconSize = { fontSize: '24px' };
  const queryParams = new URLSearchParams(window.location.search);
  const queryWebView = queryParams.get(APP_CONSTANS.QUERY_WEB_VIEW_FROM_APP);
  const isSigningOutFinish = useSelector((state) => state.auth.isSigningOutFinish);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSigningOutFinish) {
      dispatch(setManualLogout(true));
    }
  }, [dispatch, isSigningOutFinish]);

  return (
    <ul className={styles.navList}>
      {showListForSuperuser && queryWebView !== "true" && (
        <div className={styles.superuserList}>
          <a
            data-e2e="switch-to-superuser"
            href={`${APP_CONSTANS.BASE_NEW_SUPERUSER_UI}`}
            onClick={() => setOpenedMenu(false)}
          >
            <li>Switch to Superuser</li>
          </a>

          <a
            data-e2e="switch-to-customer"
            href={`${APP_CONSTANS.SUB_DOMEN_SSR}${APP_PATH.CUSTOMER}`}
            onClick={() => setOpenedMenu(false)}
          >
            <li>Switch to Customer</li>
          </a>

          <a
            data-e2e="switch-to-order-editor"
            href={`${APP_CONSTANS.SUB_DOMEN_SSR}${APP_PATH.ORDER_EDITOR}`}
            onClick={() => setOpenedMenu(false)}
          >
            <li>Switch to Order Editor</li>
          </a>

          <a
            data-e2e="switch-to-photo-editor"
            href={`${APP_CONSTANS.SUB_DOMEN_SSR}${APP_PATH.PHOTO_EDITOR}`}
            onClick={() => setOpenedMenu(false)}
          >
            <li>Switch to Photo Editor</li>
          </a>
        </div>
      )}
      {
        queryWebView !== "true" && (
          <>
            <Link
              data-e2e="nav-home"
              to={APP_PATH.ROOT}
              onClick={() => setOpenedMenu(false)}
            >
              <li>
                <HomeOutlined style={iconSize} />
                Home
              </li>
            </Link>
            <Link
              data-e2e="nav-profile"
              to={APP_PATH.PROFILE}
              onClick={() => setOpenedMenu(false)}
            >
              <li>
                <UserOutlined style={iconSize} />
                Profile
              </li>
            </Link>
          </>
        )
      }

      <Link
        data-e2e="nav-help"
        to={APP_PATH.FAQ}
        onClick={() => setOpenedMenu(false)}
      >
        <li className={styles.itemHelp}>
          <ExclamationCircleOutlined rotate="180" style={iconSize} />
          Help
        </li>
      </Link>

      {showOnboardingButton && (
        <Link
          data-e2e="nav-view-onboard"
          to={APP_PATH.PROFILE}
          className={styles.onboardingBtn}
          onClick={() => setShowOnboarding(true)}
        >
          <li>
            <BulbOutlined style={iconSize} />
            View UI Walkthrough
          </li>
        </Link>
      )}

      {
        queryWebView !== "true" && (
          <>
            <li className={styles.divider}></li>

            <li data-e2e="nav-sign-out" onClick={() => dispatch(signOut())}>
              <LogoutOutlined style={iconSize} />
              Sign out
            </li>
          </>
        )
      }
    </ul>
  );
};
