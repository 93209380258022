import { ACTION_TYPES } from './types';

export const getUser = () => {
  return {
    type: ACTION_TYPES.GET_USER,
    payload: {},
  };
};

export const getUserPrefs = () => {
  return {
    type: ACTION_TYPES.GET_USER_PREFS,
    payload: {},
  };
};

export const getUserClaims = () => {
  return {
    type: ACTION_TYPES.GET_USER_CLAIMS,
    payload: {},
  };
};

export const getUserAccount = () => {
  return {
    type: ACTION_TYPES.GET_USER_ACCOUNT,
    payload: {},
  };
};

export const getUserAccountPrefs = () => {
  return {
    type: ACTION_TYPES.GET_USER_ACCOUNT_PREFS,
    payload: {},
  };
};

export const getUserAddresses = () => {
  return {
    type: ACTION_TYPES.GET_USER_ADDRESSES,
    payload: [],
  };
};

export const getUserCountries = () => {
  return {
    type: ACTION_TYPES.GET_USER_COUNTRIES,
    payload: [],
  };
};

export const getUserRegions = (countryCode) => {
  return {
    type: ACTION_TYPES.GET_USER_REGIONS,
    payload: countryCode,
  };
};

export const getOnboarding = () => {
  return {
    type: ACTION_TYPES.GET_USER_ONBOARD,
    payload: null,
  };
};

export const getPurchaseMethods = () => {
  return {
    type: ACTION_TYPES.GET_USER_PURCHASE_METHODS,
    payload: null,
  };
}

export const getPurchaseMethodCount = () => {
  return {
    type: ACTION_TYPES.GET_USER_PURCHASE_METHODS_COUNT,
    payload: null,
  };
}

export const getPurchaseTargets = () => {
  return {
    type: ACTION_TYPES.GET_USER_PURCHASE_TARGETS,
    payload: null,
  };
}

export const setShowOnboard = (id) => {
  return {
    type: ACTION_TYPES.SET_USER_SHOW_ONBOARD,
    payload: id,
  };
};

export const showOnboard = (isShow) => {
  return {
    type: ACTION_TYPES.SET_USER_IS_SHOW_BOARD,
    payload: isShow,
  };
};

export const getUserRequests = () => {
  return {
    type: ACTION_TYPES.GET_USER_REQUESTS,
    payload: [],
  };
};

export const setUserLocation = (location) => {
  return {
    type: ACTION_TYPES.GET_USER_LOCATION,
    payload: location,
  };
};
export const setUserLocationStatus = (payload) => {
  return {
    type: ACTION_TYPES.SET_USER_LOCATION_STATUS,
    payload: payload,
  };
};
export const setLevelUgrade = (payload) => {
  return {
    type: ACTION_TYPES.SET_USER_LEVEL_UPGRADE,
    payload: payload,
  };
};
export const setUserComment = (payload) => {
  return {
    type: ACTION_TYPES.SET_USER_COMMENT,
    payload: payload,
  };
};

export const postUserNotifications = (payload) => {
  return {
    type: ACTION_TYPES.POST_USER_PREFS_NOTIFICATIONS,
    payload: payload,
  };
};

export const postUserIosNotify = (payload) => {
  return {
    type: ACTION_TYPES.POST_USER_PREF_IOS_APP_NOTIFY,
    payload: payload,
  };
};

export const postAccountPrefs = (payload) => {
  return {
    type: ACTION_TYPES.POST_ACCOUNT_PREFS,
    payload: payload,
  };
};

export const postUserShowOnboard = (id) => {
  return {
    type: ACTION_TYPES.POST_USER_SHOW_ONBOARD,
    payload: id,
  };
};

export const postUserPurchaseMethod = (data) => {
  return {
    type: ACTION_TYPES.POST_USER_PURCHASE_METHOD,
    payload: data,
  };
};

export const putAccount = (payload) => {
  return {
    type: ACTION_TYPES.PUT_USER_ACCOUNT,
    payload: payload,
  };
};

export const patchUser = (payload) => {
  return {
    type: ACTION_TYPES.PATCH_USER,
    payload: payload,
  };
};

export const patchUserNewPassword = (payload) => {
  return {
    type: ACTION_TYPES.PATCH_USER_PREFS_CHANGE_PASSWORD,
    payload: payload,
  };
};

export const patchUserAccount = (payload) => {
  return {
    type: ACTION_TYPES.PATCH_USER_ACCOUNT,
    payload: payload,
  };
};

export const patchUserAddress = (payload) => {
  return {
    type: ACTION_TYPES.PATCH_USER_ADDRESS,
    payload: payload,
  };
};

export const deleteAccount = () => {
  return {
    type: ACTION_TYPES.DELETE_USER_ACCOUNT,
    payload: null,
  };
};

export const deletePurchaseMethod = (id) => {
  return {
    type: ACTION_TYPES.DELETE_USER_PURCHASE_METHOD,
    payload: id,
  };
}

export const allowGeolocation = (payload) => {
  return {
    type: ACTION_TYPES.ALLOW_USER_GEOLOCATION,
    payload: payload || false,
  };
};
