import { useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import { APP_CONSTANS } from 'shared/constants';
import { calculateDistanceBetweenTwoPoints } from 'shared/utils/calculateDistanceBetweenTwoPoints';
import { convertMetersToMiles } from 'shared/utils/convertMetersToMiles';
import {
  getCurrentJobCheckPoints,
  postCurrentJobCheckOut,
  setCurrentJobActiveCheckPoint,
  postCurrentJobCheckIn,
} from 'store/jobs';

const CHECK_IN_STATUS = 'check_in';

export const useCheckPoints = () => {
  const systemPrefs = useSelector((state) => state.auth.systemPrefAll);
  const checkPoints = useSelector(
    (state) => state.jobs.currentJobCheckPoints.data,
  );
  const checkPointsLoading = useSelector(
    (state) => state.jobs.isLoadingCurrentJobCheckPoints,
  );
  const fulfilmentId = useSelector((state) => state.jobs.currentJob?.id);
  const currentOrder = useSelector((state) => state.jobs.currentOrder);
  const activeCheckPoint = useSelector(
    (state) => state.jobs.currentJobActiveCheckPoint,
  );

  const [checkInDistanse, setCheckInDistanse] = useState();
  const [showLocationEnableModal, setShowLocationEnableModal] = useState(false);
  const [showLongDistanceModal, setShowLongDistanceModal] = useState(false);
  const [showLongDistanceModalWasSown, setShowLongDistanceModalWasSown] = useState(false);
  const [showCheckInWithoutLocationModal, setShowCheckInWithoutLocationModal] =
    useState(false);
  const [showGetPositionError, setShowGetPositionError] = useState(false);

  const [locationEnableModalWasShown, setLocationEnableModalWasShown] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(()=> {
    if(!systemPrefs?.data) {
      setCheckInDistanse(0.5);
      return
    }
    const distanse = systemPrefs?.data.find(e => e.preference_id === APP_CONSTANS.SYSTEM_PREFERENCES_ID.CHECKIN_MIN_DISTANCE.id).value;
    setCheckInDistanse(distanse);

  },[systemPrefs]);

  const { coords, isGeolocationEnabled, positionError, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    watchPosition: true,
    isOptimisticGeolocationEnabled: false,
    userDecisionTimeout: 3000,
  });

  const handleGetPosition = (value) => {
    if (!value) {
      getPosition();
    }
  };

  const handleSetShowLocationEnableModal = (value) => {
    setShowLocationEnableModal(value);
    handleGetPosition(value);
  };

  const handleSetShowCheckInWithoutLocationModal = (value) => {
    setShowCheckInWithoutLocationModal(value);
    handleGetPosition(value);
  };
  const { latitude: userLatitude, longitude: userLongitude } = coords || {};
  const { location_lat: orderLatitude, location_long: orderLongitude } =
    currentOrder || {};

  const handleCheckIn = ({ latitude, longitude }, coordinatesExist) => {
    let distanceToOrderInMeters;
    let distanceToOrder = 0;
    if (latitude && longitude) {
      distanceToOrderInMeters =
        calculateDistanceBetweenTwoPoints({
          lat1: +latitude,
          lng1: +longitude,
          lat2: +orderLatitude,
          lng2: +orderLongitude,
        }) || 0;
      distanceToOrder = convertMetersToMiles(distanceToOrderInMeters);
    }
    if (!coordinatesExist) {
      setShowCheckInWithoutLocationModal(true);
      return
    }  
    if (
      distanceToOrder > checkInDistanse &&
      !showLongDistanceModal && !showLongDistanceModalWasSown
    ) {
      setShowLongDistanceModal(true);
      setShowLongDistanceModalWasSown(true);
      return
    } 
    if (
      distanceToOrder > checkInDistanse &&
      showLongDistanceModalWasSown
    ) {
      dispatch(postCurrentJobCheckIn({ latitude, longitude }));
      return
    } 
    if (coordinatesExist && distanceToOrder <= checkInDistanse ) {
      dispatch(postCurrentJobCheckIn({ latitude, longitude }));
    }
  };

  const handleCheckOut = ({ latitude, longitude, checkPointId }) => {
    if (latitude && longitude && isGeolocationEnabled) {
      dispatch(
        postCurrentJobCheckOut({
          latitude,
          longitude,
          checkPointId: checkPointId,
        }),
      );
    }
  };

  const handleButtonClick = () => {
    if (!isGeolocationEnabled) {
      getPosition();
    }

    if (positionError) {
      setShowGetPositionError(positionError);
      return;
    }

    const coordinatesExist = userLatitude && userLongitude;

    if (!coordinatesExist && !locationEnableModalWasShown) {
      setShowLocationEnableModal(true);
      return;
    }

    const coordinates = {
      latitude: userLatitude,
      longitude: userLongitude,
    };
    if (activeCheckPoint) {
      handleCheckOut({
        ...coordinates,
        checkPointId: activeCheckPoint.id,
      });
    } else {
      handleCheckIn(coordinates, coordinatesExist);
    }
  };

  useEffect(() => {
    if (fulfilmentId) {
      dispatch(getCurrentJobCheckPoints(fulfilmentId));
    }
  }, [dispatch, fulfilmentId]);

  useEffect(() => {
    const newActiveCheckPoint = checkPoints && checkPoints.find(
      (checkPoint) => checkPoint.status === CHECK_IN_STATUS,
    );
    dispatch(setCurrentJobActiveCheckPoint(newActiveCheckPoint));
  }, [checkPoints, dispatch]);

  useEffect(() => {
    if (showLocationEnableModal) {
      setLocationEnableModalWasShown(true);
    }
  }, [showLocationEnableModal]);

  return {
    checkPoints,
    checkPointsLoading,
    activeCheckPoint,
    handleButtonClick,
    modals: {
      showLocationEnableModal,
      showLongDistanceModal,
      showCheckInWithoutLocationModal,
      setShowLocationEnableModal: handleSetShowLocationEnableModal,
      setShowLongDistanceModal,
      setShowCheckInWithoutLocationModal:
        handleSetShowCheckInWithoutLocationModal,
      showGetPositionError,
      setShowGetPositionError,
    },
  };
};
