import { ACTION_TYPES } from './types';

export const getToken = () => {
  return {
    type: ACTION_TYPES.GET_TOKEN,
    payload: '',
  };
};

export const setToken = (token) => {
  return {
    type: ACTION_TYPES.SET_TOKEN,
    payload: token,
  };
};

export const signOut = () => {
  return {
    type: ACTION_TYPES.SIGN_OUT,
  };
};

export const getSystemPref = (id) => {
  return {
    type: ACTION_TYPES.GET_SYSTEM_PREFERENCE,
    payload: id,
  };
};

export const getSystemPrefAll = () => {
  return {
    type: ACTION_TYPES.GET_SYSTEM_PREFERENCES_ALL,
    payload: '',
  };
};

export const setManualLogout = (manualLogout) => {
  return {
    type: ACTION_TYPES.SET_TOKEN,
    payload: manualLogout,
  };
};