import React, { useCallback, useEffect } from 'react';
import { Modal } from 'antd';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const ModalWindow = ({
  modalVisible,
  content,
  modalHidden,
  title,
  okText,
  cancelText,
  notToRiseTop,
  riseTop,
  footer,
  closable,
  hasBorderFooter,
  desktopView,
  fullModalOnMobile,
  smallBlock,
  carousel,
  carouselFiles,
  onCancel,
  style,
}) => {
  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    modalHidden(false);
  }, [modalHidden, onCancel]);

  useEffect(() => {
    // document.body.style = 'overflow: hidden;';
  }, []);

  const modalStyle = style ? { zIndex: 300, ...style } : { zIndex: 300 };

  return (
    <Modal
      style={modalStyle}
      className={clsx(
        styles.modalContainer,
        riseTop ? styles.addTop : '',
        notToRiseTop ? styles.defaultTop : '',
        !hasBorderFooter && !carousel && !carouselFiles
          ? styles.hideBorderFooter
          : '',
        title && !closable
          ? styles.modalWithHeader
          : title && closable
          ? styles.modalWithHeaderAndClose
          : '',
        fullModalOnMobile ? styles.fullModal : '',
        smallBlock && !carousel && !carouselFiles
          ? styles.smallWidth
          : !smallBlock && !carousel && !carouselFiles
          ? styles.normalWidth
          : carousel
          ? styles.modalCarousel
          : '',
        carouselFiles ? styles.carouselFiles : '',
      )}
      wrapClassName={fullModalOnMobile ? styles.wrapBlockOnMobile : ''}
      onCancel={handleCancel}
      title={title ? title : null}
      centered
      visible={modalVisible}
      okText={okText ? okText : null}
      cancelText={cancelText ? cancelText : null}
      footer={footer}
      closable={closable}
      mask={!desktopView && fullModalOnMobile ? false : true}
      maskStyle={{ zIndex: 200 }}
      destroyOnClose={true}
    >
      {content}
    </Modal>
  );
};
