export const ACTION_TYPES = Object.freeze({
  GET_JOBS_ORDERS: '@@JOBS/GET_ORDERS',
  GET_JOBS_ORDERS_PENDING: '@@JOBS/GET_ORDERS_PENDING',
  GET_JOBS_ORDERS_FULFILLED: '@@JOBS/GET_ORDERS_FULFILLED',
  GET_JOBS_ORDERS_REJECTED: '@@JOBS/GET_ORDERS_REJECTED',
  GET_JOBS_FULFILMENTS: '@@JOBS/GET_FULFILMENTS',
  GET_JOBS_FULFILMENTS_PENDING: '@@JOBS/GET_FULFILMENTS_PENDING',
  GET_JOBS_FULFILMENTS_FULFILLED: '@@JOBS/GET_FULFILMENTS_FULFILLED',
  GET_JOBS_FULFILMENTS_REJECTED: '@@JOBS/GET_FULFILMENTS_REJECTED',

  GET_JOBS_CURRENT_JOB_CHECK_POINTS: '@@JOBS/GET_JOBS_CURRENT_JOB_CHECK_POINTS',
  GET_JOBS_CURRENT_JOB_CHECK_POINTS_PENDING:
    '@@JOBS/GET_JOBS_CURRENT_JOB_CHECK_POINTS_PENDING',
  GET_JOBS_CURRENT_JOB_CHECK_POINTS_FULFILLED:
    '@@JOBS/GET_JOBS_CURRENT_JOB_CHECK_POINTS_FULFILLED',
  GET_JOBS_CURRENT_JOB_CHECK_POINTS_REJECTED:
    '@@JOBS/GET_JOBS_CURRENT_JOB_CHECK_POINTS_REJECTED',

  GET_JOBS_CURRENT_ORDER: '@@JOBS/GET_JOBS_CURRENT_ORDER',
  GET_JOBS_CURRENT_ORDER_PENDING: '@@JOBS/GET_JOBS_CURRENT_ORDER_PENDING',
  GET_JOBS_CURRENT_ORDER_FULFILLED: '@@JOBS/GET_JOBS_CURRENT_ORDER_FULFILLED',
  GET_JOBS_CURRENT_ORDER_REJECTED: '@@JOBS/GET_JOBS_CURRENT_ORDER_REJECTED',

  GET_JOBS_CURRENT_JOB: '@@JOBS/GET_JOBS_CURRENT_JOB',
  GET_JOBS_CURRENT_JOB_PENDING: '@@JOBS/GET_JOBS_CURRENT_JOB_PENDING',
  GET_JOBS_CURRENT_JOB_FULFILLED: '@@JOBS/GET_JOBS_CURRENT_JOB_FULFILLED',
  GET_JOBS_CURRENT_JOB_REJECTED: '@@JOBS/GET_JOBS_CURRENT_JOB_REJECTED',

  GET_JOBS_CURRENT_FULFILMENT: '@@JOBS/GET_JOBS_CURRENT_FULFILMENT',
  GET_JOBS_CURRENT_FULFILMENT_PENDING:
    '@@JOBS/GET_JOBS_CURRENT_FULFILMENT_PENDING',
  GET_JOBS_CURRENT_FULFILMENT_FULFILLED:
    '@@JOBS/GET_JOBS_CURRENT_FULFILMENT_FULFILLED',
  GET_JOBS_CURRENT_FULFILMENT_REJECTED:
    '@@JOBS/GET_JOBS_CURRENT_FULFILMENT_REJECTED',
  GET_JOBS_CURRENT_DESCRIPTION: '@@JOBS/GET_JOBS_CURRENT_DESCRIPTION',
  GET_JOBS_CURRENT_DESCRIPTION_PENDING:
    '@@JOBS/GET_JOBS_CURRENT_DESCRIPTION_PENDING',
  GET_JOBS_CURRENT_DESCRIPTION_FULFILLED:
    '@@JOBS/GET_JOBS_CURRENT_DESCRIPTION_FULFILLED',
  GET_JOBS_CURRENT_DESCRIPTION_REJECTED:
    '@@JOBS/GET_JOBS_CURRENT_DESCRIPTION_REJECTED',
  GET_JOBS_FILES_IN_FULFILMENT: '@@JOBS/GET_JOBS_FILES_IN_FULFILMENT',
  GET_JOBS_FILES_IN_FULFILMENT_PENDING:
    '@@JOBS/GET_JOBS_FILES_IN_FULFILMENT_PENDING',
  GET_JOBS_FILES_IN_FULFILMENT_FULFILLED:
    '@@JOBS/GET_JOBS_FILES_IN_FULFILMENT_FULFILLED',
  GET_JOBS_FILES_IN_FULFILMENT_REJECTED:
    '@@JOBS/GET_JOBS_FILES_IN_FULFILMENT_REJECTED',

  GET_JOBS_COMMENTS_IN_FULFILMENT: '@@JOBS/GET_JOBS_COMMENTS_IN_FULFILMENT',
  GET_JOBS_COMMENTS_IN_FULFILMENT_PENDING:
    '@@JOBS/GET_JOBS_COMMENTS_IN_FULFILMENT_PENDING',
  GET_JOBS_COMMENTS_IN_FULFILMENT_FULFILLED:
    '@@JOBS/GET_JOBS_COMMENTS_IN_FULFILMENT_FULFILLED',
  GET_JOBS_COMMENTS_IN_FULFILMENT_REJECTED:
    '@@JOBS/GET_JOBS_COMMENTS_IN_FULFILMENT_REJECTED',

  GET_ALL_COMPLICATIONS: '@@JOBS/GET_ALL_COMPLICATIONS',
  GET_ALL_COMPLICATIONS_PENDING: '@@JOBS/GET_ALL_COMPLICATIONS_PENDING',
  GET_ALL_COMPLICATIONS_FULFILLED: '@@JOBS/GET_ALL_COMPLICATIONS_FULFILLED',
  GET_ALL_COMPLICATIONS_REJECTED: '@@JOBS/GET_ALL_COMPLICATIONS_REJECTED',

  GET_ALL_COMPLICATIONS_CHILDREN: '@@JOBS/GET_ALL_COMPLICATIONS_CHILDREN',
  GET_ALL_COMPLICATIONS_CHILDREN_PENDING:
    '@@JOBS/GET_ALL_COMPLICATIONS_CHILDREN_PENDING',
  GET_ALL_COMPLICATIONS_CHILDREN_FULFILLED:
    '@@JOBS/GET_ALL_COMPLICATIONS_CHILDREN_FULFILLED',
  GET_ALL_COMPLICATIONS_CHILDREN_REJECTED:
    '@@JOBS/GET_ALL_COMPLICATIONS_CHILDREN_REJECTED',

  GET_COMPLICATION_CHILD: '@@JOBS/GET_COMPLICATION_CHILD',
  GET_COMPLICATION_CHILD_PENDING: '@@JOBS/GET_COMPLICATION_CHILD_PENDING',
  GET_COMPLICATION_CHILD_FULFILLED: '@@JOBS/GET_COMPLICATION_CHILD_FULFILLED',
  GET_COMPLICATION_CHILD_REJECTED: '@@JOBS/GET_COMPLICATION_CHILD_REJECTED',

  GET_JOBS_REQUESTS_LIST: '@@JOBS/GET_JOBS_REQUESTS_LIST',
  GET_JOBS_REQUESTS_LIST_PENDING: '@@JOBS/GET_JOBS_REQUESTS_LIST_PENDING',
  GET_JOBS_REQUESTS_LIST_FULFILLED: '@@JOBS/GET_JOBS_REQUESTS_LIST_FULFILLED',
  GET_JOBS_REQUESTS_LIST_REJECTED: '@@JOBS/GET_JOBS_REQUESTS_LIST_REJECTED',

  GET_CURRENCIES: '@@JOBS/GET_CURRENCIES',
  GET_CURRENCIES_PENDING: '@@JOBS/GET_CURRENCIES_PENDING',
  GET_CURRENCIES_FULFILLED: '@@JOBS/GET_CURRENCIES_FULFILLED',
  GET_CURRENCIES_REJECTED: '@@JOBS/GET_CURRENCIES_REJECTED',

  GET_JOB_STATES: '@@JOBS/GET_JOB_STATES',
  GET_JOB_STATES_PENDING: '@@JOBS/GET_JOB_STATES_PENDING',
  GET_JOB_STATES_FULFILLED: '@@JOBS/GET_JOB_STATES_FULFILLED',
  GET_JOB_STATES_REJECTED: '@@JOBS/GET_JOB_STATES_REJECTED',

  SET_JOBS_CURRENT_JOB_ID: '@@JOBS/SET_JOBS_CURRENT_JOB_ID',
  SET_JOBS_CURRENT_JOB_DESCRIPTION_ID:
    '@@JOBS/SET_JOBS_CURRENT_JOB_DESCRIPTION_ID',
  SET_JOBS_CURRENT_JOB_SUBMIT: '@@JOBS/SET_JOBS_CURRENT_JOB_SUBMIT',

  SET_JOBS_ACCEPT_ORDER: '@@JOBS/SET_JOBS_ACCEPT_ORDER',
  SET_JOBS_ACCEPT_ORDER_PENDING: '@@JOBS/SET_JOBS_ACCEPT_ORDER_PENDING',
  SET_JOBS_ACCEPT_ORDER_FULFILLED: '@@JOBS/SET_JOBS_ACCEPT_ORDER_FULFILLED',
  SET_JOBS_ACCEPT_ORDER_REJECTED: '@@JOBS/SET_JOBS_ACCEPT_ORDER_REJECTED',

  SET_JOBS_DECLINE_CURRENT_FULFILMENT:
    '@@JOBS/SET_JOBS_DECLINE_CURRENT_FULFILMENT',
  SET_JOBS_REQUEST_TIME_FULFILMENT: '@@JOBS/SET_JOBS_REQUEST_TIME_FULFILMENT',
  SET_JOBS_READED_COMMENTS_IN_FULFILMENT:
    '@@JOBS/SET_JOBS_READED_COMMENTS_IN_FULFILMENT',

  SET_JOBS_CURRENT_JOB_ACTIVE_CHECK_POINT:
    '@@JOBS/SET_JOBS_CURRENT_JOB_ACTIVE_CHECK_POINT',

  POST_JOBS_CURRENT_JOB_CHECK_IN: '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_IN',
  POST_JOBS_CURRENT_JOB_CHECK_IN_PENDING:
    '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_IN_PENDING',
  POST_JOBS_CURRENT_JOB_CHECK_IN_FULFILLED:
    '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_IN_FULFILLED',
  POST_JOBS_CURRENT_JOB_CHECK_IN_REJECTED:
    '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_IN_REJECTED',

  POST_JOBS_CURRENT_JOB_CHECK_OUT: '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_OUT',
  POST_JOBS_CURRENT_JOB_CHECK_OUT_PENDING:
    '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_OUT_PENDING',
  POST_JOBS_CURRENT_JOB_CHECK_OUT_FULFILLED:
    '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_OUT_FULFILLED',
  POST_JOBS_CURRENT_JOB_CHECK_OUT_REJECTED:
    '@@JOBS/POST_JOBS_CURRENT_JOB_CHECK_OUT_REJECTED',

  DELETE_JOBS_CURRENT_FILE: '@@JOBS/DELETE_JOBS_CURRENT_FILE',

  DELETE_CURRENT_JOB_DATA: '@@JOBS/DELETE_CURRENT_JOB_DATA',

  DELETE_FILES_IN_FULFILMENT: '@@JOBS/DELETE_FILES_IN_FULFILMENT',
});
