import { APP_CONSTANS } from '../constants';

export const signOutProcess = (isManualLogout = false) => {
  const redirectURL = isManualLogout ? '' : `?redirect=${encodeURIComponent(window.location.href)}`;
  window.localStorage.removeItem(APP_CONSTANS.LS_TOKEN);
  window.localStorage.removeItem(APP_CONSTANS.LS_SESSION_EXP);
  clearInterval(window.localStorage.getItem(APP_CONSTANS.LS_INTERVAL_JOBS_ID));
  window.localStorage.removeItem(APP_CONSTANS.LS_INTERVAL_JOBS_ID);
  window.location.href = `${APP_CONSTANS.DOMAIN_LOGIN}${redirectURL}`;
  return null;
};
