import React, { useState, useEffect } from 'react';
import { useOnMount } from '../../shared/hooks/useOnMount.hook';
import { Loader } from '../../components/Loader'; // Your main loader
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/user/actions';
import { setToken } from '../../store/auth/actions';
import { useHistory, useLocation } from 'react-router';
import { APP_CONSTANS } from '../../shared/constants';
import { setSessionLifeTime } from '../../shared/utils/setSessionLifeTime';
import { isSessionLife } from '../../shared/utils/isSessionLife';
import { signOutProcess } from '../../shared/utils/signout';
import { initialisePush } from './pushNotify';

const AppContainerWrapper = () => {
  const [render, setRender] = useState(<Loader />);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const isSignOut = useSelector((state) => state.auth.isSigningOutFinish);
  const isManualLogout = useSelector((state) => state.auth.manualLogout);
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if(token && isAuthorized) {
      initialisePush(token);
    }
  }, [token, isAuthorized])

  const renderModule = async () => {
    let module;
    let isAuthorizedUser = false;
    const queryParams = new URLSearchParams(location.search);
    
    if (!isSignOut && !isManualLogout) {
      if (queryParams.has(APP_CONSTANS.TOKEN_URL)) {
        const urlToken = queryParams.get(APP_CONSTANS.TOKEN_URL);
        dispatch(setToken(urlToken));
        queryParams.delete(APP_CONSTANS.TOKEN_URL);
        dispatch(getUser());
        window.localStorage.setItem(APP_CONSTANS.LS_TOKEN, urlToken);
        setSessionLifeTime();
        isSessionLife();
        isAuthorizedUser = true;
        setIsAuthorized(true);
        history.replace({
          search: queryParams.toString(),
        });
      } else {
        const savedToken = window.localStorage.getItem(APP_CONSTANS.LS_TOKEN);
        if (savedToken) {
          dispatch(setToken(savedToken));
          dispatch(getUser());
          isSessionLife();
          isAuthorizedUser = true;
          setIsAuthorized(true);
        } else {
          signOutProcess();
          return;
        }
      }
      if (
        queryParams.has(APP_CONSTANS.ID_URL) &&
        queryParams.has(APP_CONSTANS.TYPE_URL)
      ) {
        const urlId = queryParams.get(APP_CONSTANS.ID_URL);
        const urlType = queryParams.get(APP_CONSTANS.TYPE_URL);
        queryParams.delete(APP_CONSTANS.ID_URL);
        queryParams.delete(APP_CONSTANS.TYPE_URL);
        urlType === APP_CONSTANS.JOB &&
          history.push(`/${APP_CONSTANS.FULFILMENT}/${urlId}`);
        urlType === APP_CONSTANS.ORDER &&
          history.push(`/${APP_CONSTANS.ORDER}/${urlId}`);
      }
    }

    if (!isAuthorizedUser) {
      module = await import('../../features/Routes/UnauthorizedRoutes');
    } else {
      module = await import('../Routes/PhotographerRoutes');
    }

    const ModuleComponent = module.default;

    setRender(<ModuleComponent />);
  };

  useOnMount(() => {
    renderModule();
  });

  return render;
};

export default AppContainerWrapper;
