import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Button } from 'antd';
import { SendOutlined, MessageOutlined } from '@ant-design/icons';
import {
  getCommentsInFulfilment,
  setReadedComments,
} from '../../store/jobs/actions';
import { setUserComment } from '../../store/user/actions';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { CommentItem } from './CommentItem';

export const Comment = ({ showComment, setShowComment }) => {
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const commentsBlockRef = useRef(null);
  const readedCommentsQuids = useRef([]);
  const [comment, setComment] = useState('');
  const userId = useSelector((state) => state.user.user.id);
  const currentComments = useSelector((state) => state.jobs.currentComments);
  const fulfilmentId = useSelector((state) => state.jobs.currentJob.id);

  useEffect(() => {
    dispatch(getCommentsInFulfilment());
  }, [dispatch]);

  const getOnlyUniqueComments = (commentIds) => {
    if (readedCommentsQuids.current.length > 0) {
      return readedCommentsQuids.current.filter(quid => {
        return !commentIds.includes(quid);
      })
    } else {
      readedCommentsQuids.current = commentIds;
      return commentIds;
    }
  };

  useEffect(() => {
    if (currentComments && currentComments?.data?.length > 0) {
      const unreadedCommentsIds = currentComments.data
        .filter(comment => !comment.hasOwnProperty('exposed_at'))
        .map(comment => comment.id);
      if (unreadedCommentsIds.length > 0) {
        const onlyUniqueComments = getOnlyUniqueComments(unreadedCommentsIds);
        if (onlyUniqueComments.length > 0) {
          const dataToBack = {
            ids: onlyUniqueComments,
          };
          dispatch(setReadedComments(dataToBack));
        }
      }
    }
  }, [currentComments, dispatch]);

  const postComment = (event) => {
    const heightTextarea = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = heightTextarea + 'px';
    setComment(event.target.value);
  };

  const sendComment = () => {
    setComment('');
    const dataToBack = {
      comment: comment,
      id: fulfilmentId,
      internal: false,
      object: 'fulfilment',
    };
    dispatch(setUserComment(dataToBack));
    textareaRef.current.style.height = '48px';
  };

  const onClose = () => {
    setShowComment(false);
  };

  return (
    <Drawer
      title={[
        <div key="title">
          <MessageOutlined style={{ marginRight: 10 }} />
          <span>Comments</span>
        </div>,
      ]}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={showComment}
      key="right"
      className={styles.commentsDrawer}
      footerStyle={{ display: 'flex', alignItems: 'flex-end' }}
      footer={[
        <React.Fragment key="footer">
          <textarea
            value={comment}
            onChange={postComment}
            placeholder="Write a comment..."
            ref={textareaRef}
          ></textarea>

          <Button
            type="link"
            disabled={comment.length === 0}
            className={clsx(
              comment.length > 0 ? styles.isActive : styles.isDisabled,
              styles.sendBtn,
            )}
            onClick={sendComment}
          >
            <SendOutlined style={{ color: '#FFFFFF' }} />
          </Button>
        </React.Fragment>,
      ]}
    >
      <div className={styles.comments} ref={commentsBlockRef}>
        <ul>
          {currentComments &&
            currentComments?.data && 
            currentComments?.data?.length > 0 &&
            currentComments?.data?.map((comment, index) => (
              <CommentItem
                key={index}
                index={index}
                comment={comment}
                userId={userId}
                currentComments={currentComments}
              />
            ))}
        </ul>
      </div>
    </Drawer>
  );
};
