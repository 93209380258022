export const convertUNIXToLocale = (
  timestamp,
  columnName = null,
  recordTimezone = null,
) => {
  let timestampNumber;
  if (typeof timestamp === 'number') {
    timestampNumber = timestamp;
  } else if (
    typeof timestamp !== 'number' &&
    timestamp &&
    timestamp.hasOwnProperty('props') &&
    timestamp.props.children &&
    timestamp.props.children.hasOwnProperty('props')
  ) {
    timestampNumber = timestamp.props.children.props.text;
  } else if (
    typeof timestamp !== 'number' &&
    timestamp &&
    timestamp.hasOwnProperty('props') &&
    timestamp.props.children &&
    !timestamp.props.children.hasOwnProperty('props')
  ) {
    timestampNumber = timestamp.props.children;
  } else if (
    typeof timestamp !== 'number' &&
    timestamp &&
    timestamp.hasOwnProperty('props') &&
    !timestamp.props.children &&
    timestamp.props.hasOwnProperty('text')
  ) {
    timestampNumber = columnName
      ? timestamp.props.record[columnName]
      : timestamp.props.text;
  } else {
    timestampNumber = null;
  }
  const date = timestampNumber ? new Date(timestampNumber * 1000) : null;
  if (!date) return null;
  const inLocal = date.toLocaleString('en-US', {
    timeZoneName: 'short'
  });
  if (!recordTimezone) return inLocal;
  const inTimezone = date.toLocaleString('en-US', {
    timeZone: recordTimezone,
    timeZoneName: 'short'
  });
  return inTimezone
};

export const convertUnixToLocalTime = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  return date.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const convertUnixToLocalDate = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  return date.toLocaleDateString();
};

export const convertUnixToLocalDateLong = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  return date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
