import { APP_CONSTANS } from '../constants';

export const logging = (prefix, info) => {
  const dataToBack = {
    platform: navigator.platform,
    user_agent: navigator.userAgent,
    name: localStorage.getItem('user_name'),
    email: localStorage.getItem('user_email'),
    ...(info && {info})
  };
  const path = prefix.includes('error_upload_file')
    ? APP_CONSTANS.loggingFileURL
    : APP_CONSTANS.loggingURL;
  fetch(`${path}/logging/${prefix}`, {
    method: 'POST',
    headers: {
      'Content-type': 'plain/text',
    },
    body: JSON.stringify(dataToBack),
  })
    .then((response) => {
      console.log('response - ', response);
    })
    .catch((error) => console.error(error));
};
