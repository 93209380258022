import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import logo from '../../shared/images/logo.svg';
import iosAppLogo from '../../shared/images/iosAppLogo.svg';
import styles from './styles.module.scss';
import { APP_PATH } from '../../features/Routes/helpers';
import { APP_CONSTANS } from '../../shared/constants';

const AntFooter = Layout.Footer;

export const Footer = () => {
  const CURRENT_YEAR = new Date().getFullYear();
  return (
    <AntFooter>
      <div className={styles.footerWrapper}>
        <Link to={APP_PATH.ROOT}>
          <img src={logo} alt="FotoFetch logo" />
        </Link>

        <div className={styles.links}>
          <Link to={APP_PATH.PRIVACY_POLICY}>Privacy Policy</Link>
          <Link to={APP_PATH.TERMS_OF_SERVICE}>Terms of Service</Link>
          <a href={APP_CONSTANS.IOS_APPSTORE_LINK} target="_blank" rel="noopener noreferrer">
            <img src={iosAppLogo} alt="Apple App Store logo" />
          </a>
        </div>

        <p className={styles.copyright}>
          © {CURRENT_YEAR} FotoFetch, Inc. All rights reserved
        </p>
      </div>
    </AntFooter>
  );
};
